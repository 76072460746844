<template>
  <div class="freshdeskTicketAdd">
    <form>
      <div class="form-group">
        <h4 class="m-0 mt-3">
          {{ $t('freshdesk.createTicket') }}
        </h4>
        <hr class="m-0 mb-3">
        <label>{{ $t('email') }}</label>
        <div
          style="display: flex;"
          class="mb-3"
        >
          <font-awesome-icon
            v-tooltip="$t('copyToClipboard')"
            icon="copy"
            class="mr-2 mt-3"
            @click="copyToClipboard(contact.email)"
          />
          <input
            v-model="contact.email"
            disabled
            :class="['form-control', { 'is-invalid': $validator.errors.has('subject') }]"
            type="text"
          >
        </div>
        
        <label>{{ $t('freshdesk.subject') }}</label>
        <input
          v-model="ticket.subject"
          v-focus
          :class="['form-control', { 'is-invalid': $validator.errors.has('subject') }]"
          type="text"
        >
        <hr class="m-0 mb-3">
        <label>{{ $t('description') }}</label>
        <textarea
          v-model="ticket.description"
          :class="['form-control', { 'is-invalid': $validator.errors.has('description') }]"
          rows="5"
        />
        <hr class="m-0 mb-3">
        <label>{{ $t('severity') }}</label>
        <select
          v-model="ticket.priority"
          :class="['form-control', { 'is-invalid': $validator.errors.has('priority') }]"
        >
          <option
            v-for="(prio, i) in priorities"
            :key="`prio${i}`"
            :value="prio.value"
          >
            {{ prio.name }}
          </option>
        </select>
      </div>
      <button
        class="btn btn-primary float-right"
        :disabled="!ticket.subject || !ticket.description"
        @click.prevent="createFreshdeskTicket()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="check"
        />{{ $t('freshdesk.create') }}
      </button>
      <div class="clearfix" />
    </form>
  </div>
</template>

<script>
import { errorMixin } from '@/mixins/errorMixin.js';
import historyAdapter from '@/mixins/historyAdapter.js';

export default {
  name: "FreshdeskTicketAdd",
  mixins: [
    errorMixin,
    historyAdapter
  ],
  props: {
    contact: {
      type: Object,
      required: true
    },
    installationId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      ticket: {
        priority: 1
      },
      priorities: [ 
        {
          name: 'Low',
          value: 1
        },
        {
          name: 'Medium',
          value: 2
        },
        {
          name: 'High',
          value: 3
        },
        {
          name: 'Urgent',
          value: 4
        }
      ]
    }
  },
  methods: {
    copyToClipboard (text) {
      navigator.clipboard.writeText(text);
      this.$snotify.success("The data has been copied to your clipboard.");
    },
    async createFreshdeskTicket () {
      let postObject = {
        'contact': this.contact,
        'ticket': {
          'subject': this.ticket.subject,
          'description': this.ticket.description,
          'priority': this.ticket.priority,
          'email': this.contact.email,
          'tags': [
            this.installationId
          ],
          'phone': this.contact.phone,
          'unique_external_id': this.contact.id
        }
      };
      await this.axios.post('/Freshdesk/CreateTicket', postObject)
        .then((response) => {
          if (response) {
            this.$snotify.success(this.$t('freshdesk.createdSuccessfully'));
            this.createReport(postObject, response.data.id);
            this.$emit("close");
            this.error_clear();  
          }
        })
        .catch((error) => {
          this.error_clear();
          this.error_validate(error);
        });
    },
    createReport (postObject, id) {
      let text = "Freshdesk Ticket created #" + id + " \n ------------------ \n Subject: " + postObject.ticket.subject;
      let zabbixRequest = this.sendHistoryComment(this.installationId, text, 'Freshdesk', 'Info');
      zabbixRequest.then(() => {
        this.$snotify.success("The report was successfully sent.");
        this.$eventBus.$emit('refreshHistory');
        this.$eventBus.$emit('refreshProblems');
       })
    }
  }
}
</script>

<style scoped>
.freshdeskTicketAdd .progress {
  display: flex;
  flex: 1 1 auto;
  width: 1%;
  height: 20px;
  font-size: 1rem;
}
</style>